
import PackTile                                         from '_views/profile/PackTile';
import PersonHelper                                     from '_utils/PersonHelper';
import PropTypes                                        from 'prop-types';
import React                                            from 'react';
import UserAvatar                                       from '_views/shared/UserAvatar';
import VideoModalPoster                                 from '_views/shared/VideoModalPoster';

import { toClassStr }                                   from '_utils/UiHelper';

const PT = {
  addClasses:             PropTypes.string,
  authenticityToken:      PropTypes.string,
  profileUser:            PropTypes.object,
  certifiedSubjectsList:  PropTypes.array,
  ugsSubjectsList:        PropTypes.array,
  currentUserId:                 PropTypes.number,
};

class ProfileView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this._isMounted = false;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    let classes = toClassStr(['page-shell', this.props.addClasses]);

    return (
      <div className='new-profiles-page'>
        <div className={classes}>
          {this.renderHeader()}
          {this.renderMainContent()}
        </div>
      </div>
    );
  }

  renderHeader() {
    const profileUser = this.props.profileUser;
    const profileName = PersonHelper.getProfileName(profileUser, 'preview');

    return (
      <div className='header'>
        <div className='header-container'>
          <UserAvatar 
            addClasses="profile-user-avatar"
            avatarUrl={profileUser.avatarUrl}
            avatarName={profileName}
          />
          <div className='profile-user-info'>
            <div className='user-name'>
              {profileName}
            </div>
            {this.renderProfileUserInstitution()}
            {this.renderProfileUserBio()}
          </div>
        </div>
      </div>
    )
  }

  renderMainContent() {
    return (
      <div className='main-content'>
        <div className='main-content-container'>
          {this.renderClassesCreatedSection()}
          {this.renderClassesStudyingSection()}
          {this.renderAboutBrainscapeSection()}
        </div>
      </div>
    )
  }

  renderPackTile(subject, id) {
    return (
      <PackTile 
        authenticityToken={this.props.authenticityToken}
        currentUserId={this.props.currentUserId}
        key={`subject-${id}`}
        profileUser={this.props.profileUser}
        subject={subject}
      />
    )
  }

  renderClassesCreatedSection() {
    const nonCertifiedSubjects = this.props.ugsSubjectsList;
    let packTile = [];

    if (!nonCertifiedSubjects) {
      return null;
    }

    nonCertifiedSubjects.forEach((subject) => {
      if (subject.creatorProfileId != this.props.profileUser.profileId) {
        return;
      }
  
      packTile.push(this.renderPackTile(subject, subject.packId));
    });

    return (
      <div className='section'>
        <div className='section-heading'>Classes created ({packTile.length})</div>
        <ul className="pack-items">
          {packTile}
        </ul>
      </div>
    );
  }

  renderClassesStudyingSection() {
    const certifiedSubjects =  this.props.certifiedSubjectsList;
    const nonCertifiedSubjects = this.props.ugsSubjectsList;
    const mergedSubjects = [].concat(certifiedSubjects, nonCertifiedSubjects)
    const mergedSubjectsLength =  mergedSubjects ? mergedSubjects.length : null;

    if (!mergedSubjects) {
      return null;
    }

    let packTile = mergedSubjects.map((subject) => {
      if (subject != null && subject != undefined && subject !== "") {
        return this.renderPackTile(subject, subject.sourceId || subject.packId);
      }
    });

    return (
      <div className='section'>
        <div className='section-heading'>Classes studying ({mergedSubjectsLength})</div>
        <ul className="pack-items">
          {packTile}
        </ul>
      </div>
    );
  }

  renderAboutBrainscapeSection() {
    return (
      <div className='section'>

        <div className='section-heading'>About Brainscape</div>      
        
        <div className='video-and-desc'>

          <div className='video'>
            <VideoModalPoster
              posterImageUrl={'/assets/video-poster-image.jpg'}
              shouldAutoPlay={true}
              videoUrl={'https://www.youtube.com/embed/mAxlGrrEsYs'}
            />
          </div>

          <div className='desc'>
            <p className='text-blurb'>Brainscape uses an adaptive learning algorithm that we call Confidence-Based Repetition. Our platform is scientifically proven to help you learn faster and remember longer.</p>

            {this.renderPrimaryCtas()}
          </div>
        </div>
      </div>
    )
  }

  renderPrimaryCtas() {
    if (!this.props.currentUserId) {
      return (
        <div className="primary-ctas large-ctas">

          <div className="rect-button primary-cta">
            <a href="/subjects">Find flashcards</a>
          </div>

          <div className="rect-button primary-cta emphasized" onClick={this.handleMakeFlashcardsButtonClick}>
            Make flashcards
          </div>
        </div>
      );
    }

    return (
      <div className="primary-ctas large-ctas">

        <div className="rect-button primary-cta">
          <a href="/subjects">Find flashcards</a>
        </div>

        <div className="rect-button primary-cta emphasized">
          <a href="/l/dashboard?make_flashcards=true">Make flashcards</a>
        </div>
      </div>
    );
  }

  renderProfileUserBio() {
    const profileUser = this.props.profileUser;

    if (!profileUser.bio) {
      return null;
    }

    return (
      <div className='user-bio'>
        {profileUser.bio}
      </div>
    )
  }

  renderProfileUserInstitution() {
    const profileUser = this.props.profileUser;

    if (this.isNullOrEmpty(profileUser.companyName) && this.isNullOrEmpty(profileUser.schoolName)) {
      return;
    }

    if (!this.isNullOrEmpty(profileUser.companyName) && !this.isNullOrEmpty(profileUser.schoolName)) {
      return (
        <div className='user-school-or-company'>
          <div className='label'>Company: <span>{profileUser.companyName}</span></div>
          <div className='label'>School: <span>{profileUser.schoolName}</span></div>
        </div>
      )
    }

    if (!this.isNullOrEmpty(profileUser.companyName) && this.isNullOrEmpty(profileUser.schoolName)) {
      return (
        <div className='user-school-or-company'>
          <div className='label'>Company: <span>{profileUser.companyName}</span></div>
        </div>
      )
    }

    if (this.isNullOrEmpty(profileUser.companyName) && !this.isNullOrEmpty(profileUser.schoolName)) {
      return (
        <div className='user-school-or-company'>
          <div className='label'>School: <span>{profileUser.schoolName}</span></div>
        </div>
      )
    }
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleMakeFlashcardsButtonClick = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const makeFlashcardsNavbarOption = document.querySelector('.navbar .navbar-option.make-flashcards .registration-button');

    if (makeFlashcardsNavbarOption) {
      makeFlashcardsNavbarOption.click();
    }
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  isNullOrEmpty = (elem) => {
    if (elem === null || elem.trim() === "") {
      return true;
    }
  }
}

ProfileView.propTypes = PT;

export default ProfileView;
