
// substrate and utils
import EventManager                     from '@brainscape/event-manager';
import PropTypes                        from 'prop-types';
import React                            from 'react';
import CookieHelper                     from '_utils/CookieHelper';
import SessionStorageHelper             from '_utils/SessionStorageHelper';
import UiHelper                         from '_utils/UiHelper';
                
// models
import pack                             from '_models/pack';
import userPack                         from '_models/userPack';
import userPackTransform                from '_models/userPackTransform';

// concerns
import currentUserConcern               from '_concerns/currentUserConcern';
import currentUserPacksConcern          from '_concerns/currentUserPacksConcern';

// main controllers
import DeckDetailController             from '_controllers/DeckDetailController';
import PackDetailController             from '_controllers/PackDetailController';


const PT = {  
  initialPackId:     PropTypes.node,
  initialDeckId:     PropTypes.node,
  initialCardId:     PropTypes.node,
  initialStudyMix:   PropTypes.object,
  initialTabId:      PropTypes.node,
  initialUser:       PropTypes.object,
  initialView:       PropTypes.string,
};


class AppController extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPackId:                props.initialPackId,
      currentDeckId:                props.initialDeckId,
      currentCardId:                props.initialCardId,
      currentTabId:                 props.initialTabId,
      currentUser:                  props.initialUser,
      currentUserPacks:             props.initialUserPacks,
      currentView:                  props.initialView,
      hasSeenDeckDetailFtue:        false,
      hasSeenPackDetailFtue:        false,
      isDeckDetailFtue:             false,
      isPackDetailFtue:             false,
      isShowingCachedUserPacks:     false,
      isLoadingUser:                true,
      isLoadingUserPacks:           true,
      isMobileViewportSize:         null,
      localUserPackTransforms:      {},
      transformedUserPackIds:       [],
      triggerGetTheAppModal:        false,
      getTheAppModalWasTriggered:   false
    };

    this.events = new EventManager();

    this._isMounted = false;
  }


  /*
  ==================================================
   LIFE-CYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.subscribeToEvents();
    this.manageViewport();
    this.initCurrentResources();
    this.verifyUserSessionStore();
    this.manageGetTheAppModal();

    CookieHelper.setCookie('x_tzo', new Date().getTimezoneOffset());
  }

  componentWillUnmount() {
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   INITIALIZE CORE RESOURCES
  ==================================================
  */

  initCurrentResources = () => {
    const user = this.props.initialUser;

    this.initCurrentUserData(user);
    this.initFtue();
  }

  initCurrentUserData = (user) => {
    currentUserConcern.get(user);
    currentUserPacksConcern.get(user.userId);
  }

  initFtue = () => {
    const incomingView = this.props.initialView;
    const ftueFlags = this.calculateFtueFlags(incomingView);

    this.setState(ftueFlags);
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('card:created',                       this.handleCardCreated);
    this.events.addListener('card:inserted',                      this.handleCardInserted);
    this.events.addListener('card:removed',                       this.handleCardRemoved);
    this.events.addListener('card-confidence:updated',            this.handleCardConfidenceUpdated);
    this.events.addListener('category-subscription:created',      this.handleCategorySubscriptionCreated);
    this.events.addListener('current-user:intent-selected',       this.handleCurrentUserIntentSelected);
    this.events.addListener('current-user:retrieved',             this.handleCurrentUserRetrieved);
    this.events.addListener('current-user-packs:retrieved',       this.handleCurrentUserPacksRetrieved);
    this.events.addListener('deck:created',                       this.handleDeckCreated);
    this.events.addListener('deck:removed',                       this.handleDeckRemoved);
    this.events.addListener('deck-confidences:reset',             this.handleDeckConfidencesReset);
    this.events.addListener('deck-detail-view:change-request',    this.handleDeckDetailViewChangeRequest);
    this.events.addListener('ftue:dismiss-request',               this.handleFtueDismissRequest);
    this.events.addListener('pack-detail-view:change-request',    this.handlePackDetailViewChangeRequest);
    this.events.addListener('pack:created',                       this.handlePackCreated);
    this.events.addListener('pack-duplicate:job-completed',       this.handlePackDuplicated);
    this.events.addListener('pack:removed',                       this.handlePackRemoved);
    this.events.addListener('pack:updated',                       this.handlePackUpdated);
    this.events.addListener('pack-confidences:reset',             this.handlePackConfidencesReset);
    this.events.addListener('pack-metadata:updated',              this.handlePackMetadataUpdated);
    this.events.addListener('user-packs:page-received',           this.handleUserPacksPageReceived);
  }   

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    switch (this.state.currentView) {
      case 'pack-detail':
        return this.renderPackDetailController();
      break;
      case 'deck-detail':
        return this.renderDeckDetailController();
      break;
      // case 'study':
      // break;
      // case 'account':
      // break;
    }
  }

  renderPackDetailController() {
    return (
      <PackDetailController
        initialPackId={this.state.currentPackId}
        initialTabId={this.state.currentTabId}
        initialUser={this.state.currentUser}
        initialUserPacks={this.state.currentUserPacks}
        initialView={this.state.currentView}
        isFtue={this.state.isPackDetailFtue}
        isLoadingUser={this.state.isLoadingUser}
        isLoadingUserPacks={this.state.isLoadingUserPacks}
        isShowingCachedUserPacks={this.state.isShowingCachedUserPacks}
      />
    );
  }

  renderDeckDetailController() {
    return (
      <DeckDetailController 
        initialPackId={this.state.currentPackId}
        initialDeckId={this.state.currentDeckId}
        initialCardId={this.state.currentCardId}
        initialTabId={this.state.currentTabId}
        initialUser={this.state.currentUser}
        initialUserPacks={this.state.currentUserPacks}
        initialView={this.state.currentView}
        isFtue={this.state.isDeckDetailFtue}
        isLoadingUser={this.state.isLoadingUser}
        isLoadingUserPacks={this.state.isLoadingUserPacks}
        isShowingCachedUserPacks={this.state.isShowingCachedUserPacks}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleCardChanged = (eventData) => {
    const currentUserPacks = [...this.state.currentUserPacks];
    const changedUserPackIndex = currentUserPacks.findIndex(userPack => userPack.packId == eventData.packId);

    if (changedUserPackIndex == -1) {
      return false;
    }

    const changedUserPack = currentUserPacks[changedUserPackIndex];
    changedUserPack.stats = {...changedUserPack.stats, ...eventData.packStats};
    currentUserPacks[changedUserPackIndex] = changedUserPack; // belt & suspenders :-)

    this.setState({
      currentUserPacks: currentUserPacks,
    }, () => {
      SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
    });
  }

  handleCardConfidenceUpdated = (eventData) => {
    this.handleCardChanged(eventData);
  }

  handleCardCreated = (eventData) => {
    this.handleCardChanged(eventData);
  }

  handleCardInserted = (eventData) => {
    this.handleCardChanged(eventData);
  }

  handleCardRemoved = (eventData) => {
    this.handleCardChanged(eventData);
  }

  handleCategorySubscriptionCreated = (eventData) => {  
    const category = eventData.category;
    const userId = this.props.initialUser.userId;

    this.setState({
      isLoadingUserPacks: true,
    }, () => {
      currentUserPacksConcern.fetch(userId);
    });
  }

  handleCurrentUserIntentSelected = (eventData) => {
    const currentUser = {...this.state.currentUser};
    currentUser.flags.intent = eventData?.intent || 'study';

    this.setState({
      currentUser: currentUser,
    });
  }

  handleCurrentUserRetrieved = (eventData) => {
    this.setState({
      currentUser: {...this.state.currentUser, ...eventData.currentUser},
      isLoadingUser: false,
    });
  }

  handleCurrentUserPacksRetrieved = (eventData) => {
    /*
      NOTE: User Packs are auto-paginated. This method receives either first, a cached copy of all User Packs (if it is available in Session Storage), and then in either case, a copy of all User Packs after pagination is complete. If there is no cached copy available, the UI will be updated with the first page of UserPacks via the 'handleUserPacksPageReceived' method which receives each paginated page from the server.
    */

    const userPackIds = eventData.packs.map(pack => pack.packId);
    const isFromCache = !!eventData.isFromCache;

    if (isFromCache) {
      // no need to apply local transforms, they are already active in the cached copy
      this.setState({
        currentUserPacks: eventData.packs,
        isLoadingUserPacks: true,
        isShowingCachedUserPacks: true,
        localUserPackTransforms: {},
        transformedUserPackIds: userPackIds,
      }, () => {
        this.triggerCurrentPackScrollToRequest();
      });

      return true;
    }

    // if we are here, we are dealing with fully paginated fresh data from the server. Apply local transforms, then update UI
    userPackTransform.index(this.state.currentUser.userId, eventData.packs).then(userPackTransforms => {
      let transformedPacks = [...eventData.packs];

      transformedPacks = userPack.filter(transformedPacks, userPackTransforms.filters);
      transformedPacks = userPack.sort(transformedPacks, userPackTransforms.sorters);
      const transformedUserPackIds = transformedPacks.map(transformedPack => transformedPack.packId);
      const currentUserPacks = transformedPacks;

      this.setState({
        currentUserPacks: currentUserPacks,
        isLoadingUserPacks: false,
        isShowingCachedUserPacks: false,
        localUserPackTransforms: userPackTransforms,
        transformedUserPackIds: transformedUserPackIds,
      }, () => {
        SessionStorageHelper.setItem('currentUserPacks', currentUserPacks);
        this.triggerCurrentPackScrollToRequest();
      });
    }).catch(err => {
      console.error(err);
    });
  }

  handleDeckChanged = (eventData) => {
    const currentUserPacks = [...this.state.currentUserPacks];
    const changedUserPackIndex = currentUserPacks.findIndex(userPack => userPack.packId == eventData.packId);

    if (changedUserPackIndex == -1) {
      return false;
    }

    const changedUserPack = currentUserPacks[changedUserPackIndex];
    changedUserPack.stats = {...changedUserPack.stats, ...eventData.packStats};
    currentUserPacks[changedUserPackIndex] = changedUserPack; // belt & suspenders :-)

    this.setState({
      currentUserPacks: currentUserPacks,
    }, () => {
      SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
    });
  }

  handleDeckCreated = (eventData) => {
    this.handleDeckChanged(eventData);
  }

  handleDeckRemoved = (eventData) => {
    this.handleDeckChanged(eventData);
  }

  handleDeckConfidencesReset = (eventData) => {
    this.refreshUserPack(eventData.packId);
  }

  handleDeckDetailViewChangeRequest = (eventData) => {
    const ftueFlags = this.calculateFtueFlags('deck-detail');

    this.setState({
      currentView: 'deck-detail',
      currentPackId: eventData.packId || this.state.currentPackId,
      currentDeckId: eventData.deckId || this.state.currentPackId,
      currentCardId: eventData.cardId || this.state.currentCardId,
      currentTabId: eventData.tabId || 'preview',
      ...ftueFlags,
    });
  }

  handleFtueDismissRequest = () => {
    const currentUser = {...this.state.currentUser};
    currentUser.flags.isFtue = false;

    this.setState({
      currentUser: currentUser,
      hasSeenDeckDetailFtue: true,
      hasSeenPackDetailFtue: true,
      isDeckDetailFtue: false,
      isPackDetailFtue: false,
    });
  }

  handlePackConfidencesReset = (eventData) => {
    this.refreshUserPack(eventData.packId);
  }

  handlePackCreated = (eventData) => {
    const newPack = eventData.pack;
    this.addPackToUserPacks(newPack);

    this.setState({
      currentView: 'pack-detail',
      currentPackId: eventData.pack.packId,
      currentDeckId: null,
      currentCardId: null,
      currentTabId: 'decks',
    });
  }

  handlePackDetailViewChangeRequest = (eventData) => {
    const ftueFlags = this.calculateFtueFlags('pack-detail');

    this.setState({
      currentView: 'pack-detail',
      currentPackId: eventData.packId,
      currentDeckId: eventData.deckId,
      currentCardId: eventData.cardId,
      currentTabId: 'decks',
      ...ftueFlags,
    });
  }

  handlePackDuplicated = (eventData) => {
    currentUserPacksConcern.fetch(this.props.initialUser.userId);
    this.triggerToastOpen('Class Duplicated');
  }

  handlePackMetadataUpdated = (eventData) => {
    this.refreshUserPack(eventData.packId);
  }

  handlePackRemoved = (eventData) => {
    const removedPackId = eventData.packId;
    this.removePackFromUserPacks(removedPackId);
  }

  handlePackUpdated = (eventData) => {
    const updatedPack = eventData.pack;
    this.updatePackInUserPacks(updatedPack);
  }

  handleUserPacksPageReceived = (eventData) => {
    /*
      NOTE: User Packs are auto-paginated. This method receives each page of the entire set. As soon as we get the first page of User Packs, we update the 'above the fold' UI. The newModel.paginatedIndex continues to retrieve any remaining pages in the background. When all pages of the index are received, the paginatedIndex promise will resolve with a set of all UserPacks, at which time we will finish display below the fold with the 'handleCurrentUserPacksRetrieved' method.
    */

    if (this.state.isShowingCachedUserPacks) {
      return false;
    }

    if (eventData.page > 1) {
      // in the current UI, we take page 1 and render to the user. We wait to receive the whole set before rendering again.
      return false;
    }

    const userPackIds = eventData.packs.map(pack => pack.packId);

    this.setState({
      currentUserPacks: eventData.packs,
      isLoadingUserPacks: true,
      localUserPackTransforms: {},
      transformedUserPackIds: userPackIds,
    });
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCurrentPackScrollToRequest = () => {
    EventManager.emitEvent('current-pack:scroll-to-request', {});
  }

  triggerGetTheAppModalOpen() {
    EventManager.emitEvent('get-the-app-modal:open', {});
    this.setState({ getTheAppModalWasTriggered: true });
  }

  triggerToastClose = () => {
    EventManager.emitEvent('toast:close', {});
  }

  triggerToastOpen = (message, type, duration) => {
    EventManager.emitEvent('toast:open', {
      duration: duration,
      message: message,
      position: 'top-right',
      type: type,
    });
  }


  /*
  ==================================================
   EVENT PUBLISHERS
  ==================================================
  */


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  manageGetTheAppModal = () => {
    if (this.state.currentUser.uiTriggers.modals?.getTheApp && !this.state.currentUser.flags.isFtue && !this.state.getTheAppModalWasTriggered) {
      this.setState({ triggerGetTheAppModal: true });
      this.triggerGetTheAppModalOpen({});
    }
  };

  addPackToUserPacks = (pack) => {
    let currentUserPacks = [...this.state.currentUserPacks];
    currentUserPacks.push(pack);

    currentUserPacks = userPack.filter(currentUserPacks, null); // clear filters
    currentUserPacks = userPack.sort(currentUserPacks, this.state.localUserPackTransforms?.sorters || null);

    this.setState({
      currentUserPacks: currentUserPacks,
    }, () => {
      this.triggerCurrentPackScrollToRequest();
      SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
    });
  }

  calculateFtueFlags = (incomingView) => {
    // this approach is needed to ensure FTUE is seen only once for each view

    let ftueFlags = {};

    if (this.state.currentUser?.flags.isFtue) {

      switch (incomingView) {

        case 'pack-detail':
          const isPackDetailFtue = !this.state.hasSeenPackDetailFtue;

          ftueFlags = {
            hasSeenPackDetailFtue: true,
            isPackDetailFtue: isPackDetailFtue,
          }
        break;

        case 'deck-detail':
          const isDeckDetailFtue = !this.state.hasSeenDeckDetailFtue;

          ftueFlags = {
            hasSeenDeckDetailFtue: true,
            isDeckDetailFtue: !this.state.hasSeenDeckDetailFtue,
          }
        break;
      }
    }

    return ftueFlags;
  }

  manageViewport = () => {
    UiHelper.adjustViewportHeight();
    const isMobileViewportSize = UiHelper.detectIfMobileSize();

    this.setState({
      isMobileViewportSize: isMobileViewportSize,
    });
  }

  refreshUserPack = (packId) => {
    pack.show(packId).then(updatedPack => {
      const packIndexToUpdate = this.state.currentUserPacks.findIndex(userPack => {
        return (userPack.packId == updatedPack.packId);
      });

      if (packIndexToUpdate == -1) {
        return false;
      }

      const currentUserPacks = [...this.state.currentUserPacks];

      currentUserPacks[packIndexToUpdate] = updatedPack; 

      this.setState({
        currentUserPacks: currentUserPacks,
      }, () => {
        SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
      });
    }).catch(err => {
      console.error(err);
    });
  }

  removePackFromUserPacks = (packId) => {
    const packIndexToRemove = this.state.currentUserPacks.findIndex(userPack => {
      return (userPack.packId == packId);
    });

    if (packIndexToRemove == -1) {
      return false;
    }

    let userPacks = [...this.state.currentUserPacks];
    userPacks.splice(packIndexToRemove, 1);

    this.setState({
      currentUserPacks: userPacks,
    }, () => {
      SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
    });
  }

  updatePackInUserPacks = (pack) => {
    const packIndexToUpdate = this.state.currentUserPacks.findIndex(userPack => {
      return (userPack.packId == pack.packId);
    });

    if (packIndexToUpdate == -1) {
      return false;
    }

    const currentUserPacks = [...this.state.currentUserPacks];
    const userPack = currentUserPacks[packIndexToUpdate];
    const COMMON_EDITABLE_KEYS = ['activeIconUrl', 'desc', 'fullName', 'mixType', 'name', 'permission', 'private'];

    COMMON_EDITABLE_KEYS.forEach(key => {
      userPack[key] = pack[key];
    });

    currentUserPacks[packIndexToUpdate] = userPack; 

    this.setState({
      currentUserPacks: currentUserPacks,
    }, () => {
      SessionStorageHelper.setItem('currentUserPacks', this.state.currentUserPacks);
    });
  }

  verifyUserSessionStore = () => {
    const currentUserProfile = SessionStorageHelper.getItem('currentUserProfile');

    if (currentUserProfile?.userId != this.state.currentUser?.userId) {
      SessionStorageHelper.clear();
    }
  }
}


AppController.propTypes = PT;

export default AppController;

