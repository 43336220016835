
import React                    from 'react';
import ReactHelper              from '_utils/ReactHelper';
import VideoModalPoster         from '_views/shared/VideoModalPoster';

let profileVideoModalPoster;

const profilePage = {

  init() {

    /*
    ==================================================
     INIT SUB-MODULES
    ==================================================
    */


    /*
    ==================================================
     CACHE DOM ELEMENTS
    ==================================================
    */

    profileVideoModalPoster = document.querySelector('#profile-video-modal-poster');


    /*
    ==================================================
     RENDER REACT COMPONENTS
    ==================================================
    */
    
    if (profileVideoModalPoster) {
      ReactHelper.renderComponent(<VideoModalPoster />, '#profile-video-modal-poster');
    }


    /*
    ==================================================
     WIRE DOM ELEMENTS
    ==================================================
    */
  },


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */



  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

}

export default profilePage;
