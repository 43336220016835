
import mainApp        from '_core/main-app';
import mainPublic     from '../../core/main-public';
import profilePage    from '../pages/profile/profile-page';

document.addEventListener("DOMContentLoaded", () => {
  mainApp.init();
  mainPublic.init();
  profilePage.init();
});
