
// substrate and utils
import EventManager                 from '@brainscape/event-manager';
import NumberHelper                 from '_utils/NumberHelper';
import React                        from 'react';
import StringHelper                 from '_utils/StringHelper';
import Tracker                      from '_utils/Tracker';
import { toClassStr }               from '_utils/UiHelper';

// models
import packLearner                  from '_models/packLearner';
import packLearnerTransform         from '_models/packLearnerTransform';
import userLocalStore               from '_models/userLocalStore';

// sub-components
import CopyToClipboardLink          from '_views/shared/CopyToClipboardLink';
import DynamicTooltipIcon                 from '_views/shared/DynamicTooltipIcon';
import LearnerListOptionsButton     from '_views/pack-detail/desktop/learners/LearnerListOptionsButton';
import LearnerRow                   from '_views/pack-detail/desktop/learners/LearnerRow';
import LearnerTile                  from '_views/pack-detail/desktop/learners/LearnerTile';
import LoadingOverlay               from '_views/shared/LoadingOverlay';
import LearnersColumnHeading        from '_views/pack-detail/desktop/learners/LearnersColumnHeading';
import Modal                        from '_views/shared/Modal';
import PermissionsTooltip           from '_views/shared/PermissionsTooltip';
import PillButton                   from '_views/shared/PillButton';
import Pulldown                     from '_views/shared/Pulldown';
import RadioButtonsField            from '_views/shared/RadioButtonsField';
import RoundCheckbox                from '_views/shared/RoundCheckbox';
import SimpleTextButton             from '_views/shared/SimpleTextButton';
import RichContentTooltip                from '_views/shared/RichContentTooltip';

import { 
  CircledAddButton,
  ClearButton, 
  RetractUpButton 
}                                   from '_views/shared/IconButton';

const BULK_PERMISSION_OPTIONS = [
  { id: 'preview', label: 'Preview' },
  { id: 'study', label: 'Full Study' },
  { id: 'edit', label: 'Edit' },
  { id: 'admin', label: 'Admin' },
];

const PRIVACY_OPTIONS = [
  { label: 'Public', value: 'public', className: 'public-option' },
  { label: 'Private', value: 'private', className: 'private-option' },
];



class PackLearnersSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bulkPermission: 'study',
      confirmBulkActionsMessage: '',
      currentLearner: null,
      isBulkActionsBarOpen: false,
      isBulkPermissionPulldownOpen: false,
      isConfirmBulkActionsModalOpen: false,
      isProcessingBulkAction: false,
      isProcessingPrivacyRequest: false,
      learnerDeckStats: null,
      learnerDeckStatsRetries: 0,
      learnersFilterText: '',
      learnersSortColumn: 'users.first_name',
      learnersSortDirection: 'asc',
      locallyFilteredLearners: [],
      postConfirmModalCallback: () => {},
      shouldShowAddNewLearnerBlock: false,
      shouldPulseAddLearnerButton: false,

      learnersMap: null,
    };

    /*
      this.props:
        addClasses,
        areLearnersFullyPaginated,
        areLearnersFullyPopulated,
        authenticityToken,
        hasEmptyLearnerSearchResults,
        isLoadingPackLearners,
        isMobileViewportSize,
        isProcessingLearnerPagination,
        isShowingCachedPackLearners,
        isUserPro,
        learners,
        learnersFilterText,
        learnersPageIndex,
        learnersPageSize,
        learnersSortColumn,
        learnersSortDirection,
        locallyFilteredLearners,
        metadata,
        onFilterLearnersRequest,
        onLearnerSearchBoxChange,
        onPaginateLearnersRequest,
        onRemoveLocalLearnerRequest,
        onUpdateLocalLearnerRequest,
        onSearchLearnersRequest,
        onSortLearnersRequest,
        pack,
        shouldShowNewLearnerPrompt,
        sortColumn, // users.first_name or users.last_name
        sortDirection, // asc or desc
    */

    this._isMounted = false;

    this.MAX_STATS_RETRIES = 6;
    this.STATS_RETRY_DELAY = 1000;

    this.statsRetryTimeout = null;
  }

  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this.clearTimeoutsAndIntervals();
    this.updateLearnersMap();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pack?.learners != this.props.pack?.learners) {
      this.updateLearnersMap();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }

  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    if (!(this.props.pack.learners && this.state.learnersMap)) {
      return this.renderSectionLoadingOverlay();
    }

    const learnerCount = (this.props.pack.learners?.length) ? this.props.pack.learners?.length : 0;

    if (this.props.isLoadingPackLearners && !this.props.isShowingCachedPackLearners && learnerCount < 1) {
      return this.renderSectionLoadingOverlay();
    }

    const blockClass = this.props.isMobileViewportSize ? 'tile-blocks' : 'row-blocks';
    const classes = toClassStr(['pack-learners-section', blockClass, this.props.addClasses]);

    return (
      <section className={classes}>
        {this.renderHeader()}
        {this.renderBulkActionsBar()}
        {this.renderLearnerList()}
        {this.renderFooter()}
      </section>
    );
  }

  renderSectionLoadingOverlay() {
    return (
      <LoadingOverlay
        addClasses="section-loading-overlay"
        isOpen={true}
        message="Loading Class Learners..."
        shouldTransitionIn={true}
        shouldTransitionOut={true}
      />
    );
  }

  renderHeader() {
    if (this.props.isMobileViewportSize) {
      return this.renderTilesHeader();
    }

    return this.renderRowsHeader();
  }

  renderTilesHeader() {
    return (
      <header className="pack-learner-list-header">
        <div className="table-search-or-find">
          {this.renderFilterBox()}
        </div>

        <div className="table-headings">
          <div className="list-controls">{this.renderLearnerHeading()}</div>

          <div className="learner-actions">

            {this.renderLearnerListOptionsButton()}
            {this.renderAddNewLearnerButton()}
          </div>
        </div>
      </header>
    );
  }

  renderRowsHeader() {
    const pack = this.props.pack;
    const currentUser = this.props.currentUser;

    const packId = pack.packId;
    const userId = currentUser.userId;
    const sorters = pack.localLearnerTransforms?.sorters || {};
    const isPackAdmin = (pack.permission == 'admin');

    return (
      <header className="pack-learner-list-header">
        <div className="table-search-or-find">
          {this.renderFilterBox()}
        </div>

        {<div className="learners-column-headings">
          {this.renderSelectAllCheckbox()}

          <LearnersColumnHeading
            addClasses="col col-2"
            sortDirection={sorters.fullName || 'none'}
            isSortable={isPackAdmin}
            label="Learner"
            packId={packId}
            sortKey="fullName"
            userId={userId}
          />

          <LearnersColumnHeading
            addClasses="col col-3 stat-col"
            sortDirection={sorters['stats.mastery'] || 'none'}
            isSortable={isPackAdmin}
            label="Mastery"
            packId={packId}
            sortKey="stats.mastery"
            userId={userId}
          >
            <DynamicTooltipIcon 
              addClasses="mastery-tooltip-button"
              body={this.renderMasteryTooltip()}
              delay={500}
              hasDismissButton={true}
              heading="Mastery"
              iconType="info"
              position="top"
            />
          </LearnersColumnHeading>

          <LearnersColumnHeading
            addClasses="col col-4 stat-col"
            sortDirection={sorters['stats.daysStudied'] || 'none'}
            isSortable={isPackAdmin}
            label="Days Studied"
            packId={packId}
            sortKey="stats.daysStudied"
            userId={userId}
          >
            <DynamicTooltipIcon 
              addClasses="mastery-tooltip-button"
              body={this.renderDaysStudiedTooltip()}
              delay={500}
              hasDismissButton={true}
              heading="Days Studied"
              iconType="info"
              position="top"
            />
          </LearnersColumnHeading>

          <LearnersColumnHeading
            addClasses="col col-5 stat-col"
            sortDirection={sorters['stats.timeStudied'] || 'none'}
            isSortable={isPackAdmin}
            label="Time Studied"
            packId={packId}
            sortKey="stats.timeStudied"
            userId={userId}
          >
            <DynamicTooltipIcon 
              addClasses="time-studied-tooltip-button"
              body={this.renderTimeStudiedTooltip()}
              delay={500}
              hasDismissButton={true}
              heading="Time Studied"
              iconType="info"
              position="top"
            />
          </LearnersColumnHeading>

          <LearnersColumnHeading
            addClasses="cards-studied-label col col-6 stat-col"
            sortDirection={sorters['stats.totalCardsStudied'] || 'none'}
            isSortable={isPackAdmin}
            label="Cards Studied"
            packId={packId}
            sortKey="stats.totalCardsStudied"
            userId={userId}
          >
            <DynamicTooltipIcon 
              addClasses="time-studied-tooltip-button"
              body={this.renderCardsStudiedTooltip()}
              delay={500}
              hasDismissButton={true}
              iconType="info"
              position="top"
            />
          </LearnersColumnHeading>

          <LearnersColumnHeading
            addClasses="learner-pack-permission-heading col col-7 stat-col"
            sortDirection={sorters.permission || 'none'}
            isSortable={false}
            label="Permission"
            packId={packId}
            sortKey="permission"
            userId={userId}
          >
            <PermissionsTooltip
              iconType="info"
              isPackPrivate={this.props.pack.flags.isPrivate}
              tooltipPosition="left"
            />
          </LearnersColumnHeading>

          <div className="learner-actions col col-8">
            {this.renderLearnerListOptionsButton()}
            {this.renderAddNewLearnerButton()}
          </div>
        </div>}
      </header>
    );
  }

  renderDaysStudiedTooltip() {
    return (
      <p className="body-text">
        The number of <i>unique</i> calendar days that a user studied (e.g. if
        they studied on one day a week ago, and then again a second time
        yesterday, this number would be 2). This helps you keep track of your
        learners' <i>consistency</i>.
      </p>
    );
  }

  renderTimeStudiedTooltip() {
    return (
      <p className="body-text"> 
        This equals a learner’s total focus time. Our magic formula determines and omits all the instances when it algorithmically deduces the learner was distracted.
      </p>
    );
  }

  renderCardsStudiedTooltip() {
    return (
      <>
        <div className="body-sub-heading">Unique Cards Studied</div>
        <p className="body-text">
          The *unique* number of cards that each learner has studied. (ex. If a
          learner studies the same 20 cards 100 times, this stat would be "20").
          This number can be reset to zero if a user reset his/her confidences.
        </p>
        <div className="body-sub-heading">Total Cards Studied</div>
        <p className="body-text">
          The *cumulative* number of cards that each learner has viewed and
          rated, including repeat views (ex. If a learner studies 10 cards 5
          times each, this stat would be "50").
        </p>
      </>
    );
  }

  renderMasteryTooltip() {
    return (
      <p className="body-text">
        Mastery is a composite of the current card confidence ratings on Brainscape’s 1-5 scale. If a  user has rated all cards a “5“, the user will reach a mastery of 100%.
      </p>
    );
  }

  renderSelectAllCheckbox() {
    if (this.props.pack.permission != 'admin') {
      return (
        <div className="select-all-checkbox col col-1" />
      );
    }

    const checkboxState = this.getSelectAllCheckboxState();

    return (
      <RoundCheckbox
        addClasses="select-all-checkbox col col-1"
        isChecked={checkboxState}
        isThreeState={true}
        onClick={this.handleSelectAllCheckboxClick}
        state={checkboxState}
        tooltipContent={this.renderSelectAllCheckboxTooltip()}
        tooltipPosition="right"
        value={this.props.pack.packId}
      />
    );
  }

  renderSelectAllCheckboxTooltip() {
    return (
      <RichContentTooltip
        addClasses="select-all-checkbox-tooltip" 
        body="Give multiple learners specific usage permissions or include in other group actions."
        hasDismissButton={true}
        heading="Select Learners"
      />
    );
  }

  renderLearnerHeading() {
    const sortClass = 'users.last_name';
    const directionClass =
      sortClass != '' ? this.props.learnersSortDirection : '';
    const classes = toClassStr(['learner', sortClass, directionClass]);

    return (
      <div
        className={classes}
        onClick={(e) => this.handleLearnerHeadingClick(e)}
      >
        Learner
      </div>
    );
  }

  renderFilterBox() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    const q = this.props.pack.localLearnerTransforms?.filters?.fullName || '';

    const hasTextClass =
      q.length > 0 ? 'has-text' : '';
    const classes = toClassStr(['filter-box', hasTextClass]);

    return (
      <div className={classes}>
        <i className={'icon ion-android-funnel'}></i>
        <input
          className="filter-text-input"
          type="text"
          onChange={this.handleFilterBoxChange}
          placeholder="Filter learners"
          value={q}
        />
        <ClearButton
          addClasses="clear-filter-text-button"
          onClick={this.handleClearFilterTextButtonClick}
        />
      </div>
    );
  }

  renderLearnerListOptionsButton() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    return (
      <LearnerListOptionsButton
        authenticityToken={this.props.authenticityToken}
        currentUser={this.props.currentUser}
        iconType="horizontal"
        isUserPro={this.props.currentUser.flags.isPro}
        onShowBulkActionsBarRequest={() =>
          this.handleShowBulkActionsBarRequest()
        }
        pack={this.props.pack}
        tooltipContent="Export CSV, Bulk Actions"
        tooltipPosition="top"
      />
    );
  }

  renderBulkActionsBar() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    if (this.props.isMobileViewportSize) {
      return null;
    }

    if (!this.state.isBulkActionsBarOpen) {
      return null;
    }

    const selectedCount = this.props.pack.localLearnerTransforms?.selections?.ids?.length || 0;

    return (
      <div className="bulk-actions-bar">
        <div className="selected-count">
          Bulk actions for {selectedCount} selected Learner(s)
        </div>

        <div className="bulk-actions">
          <Pulldown
            addClasses="bulk-permission-pulldown"
            isOpen={this.state.isBulkPermissionPulldownOpen}
            options={BULK_PERMISSION_OPTIONS}
            selectedValue={this.state.bulkPermission}
            shouldSuppressNullOption={false}
            onButtonClick={() => this.handleBulkPermissionPulldownButtonClick()}
            onOptionClick={(permission) =>
              this.handleBulkPermissionOptionClick(permission)
            }
            onOutsideClick={this.handleBulkPermissionOutsideClick}
          />

          <PillButton
            addClasses="bulk-permission-go-button"
            label="Go"
            onClick={this.handleBulkPermissionGoButtonClick}
            title="Apply Bulk Permissions"
          />

          <PillButton
            addClasses="bulk-remove-button"
            label="Remove"
            onClick={this.handleBulkRemoveButtonClick}
            title="Remove selected learners from this Class"
          />
        </div>

        <RetractUpButton
          onClick={this.handleBulkActionsRetractUpButton}
          title="Collapse Bulk Actions Bar"
        />

        {this.renderConfirmBulkActionsModal()}
      </div>
    );
  }

  renderConfirmBulkActionsModal() {
    return (
      <Modal
        addClasses="confirm-bulk-actions-modal"
        isOpen={this.state.isConfirmBulkActionsModalOpen}
        onCloseRequest={() => this.handleConfirmBulkActionsModalCloseRequest()}
      >
        <div className="modal-title">Caution</div>

        <div className="modal-message">
          {this.state.confirmBulkActionsMessage}
        </div>

        <div className="modal-actions">
          <PillButton
            addClasses="resolve-modal-button"
            isProcessing={this.state.isProcessingBulkAction}
            label="Ok"
            onClick={() => this.state.postConfirmModalCallback()}
          />
          <SimpleTextButton
            addClasses="cancel-modal-text-button"
            label="Cancel"
            onClick={() => this.handleConfirmBulkActionsModalCloseRequest()}
          />
        </div>
      </Modal>
    );
  }

  renderProgressTable() {
    if (!this.props.learnerDeckStats) {
      return null;
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th className="text-left">Deck</th>
            <th>Progress</th>
            <th>Mastery</th>
            <th>Unique Cards Studied</th>
          </tr>
        </thead>
        <tbody>
          {this.renderProgressRows()}
        </tbody>
      </table>
    );
  }

  renderProgressRows() {
    const progressBars = this.props.learnerDeckStats.map((deckStats, index) => {
      return this.renderProgressRow(deckStats, index);
    });

    return progressBars;
  }

  renderProgressRow(deckStats, index) {
    return (
      <tr key={index}>
        <td>{deckStats.deckName}</td>
        <td>
          <OldLearnersProgressBar
            forceRefetch={false}
            deckID={deckStats.deckId}
            masteryRatio={deckStats.mastery}
            percentages={deckStats.percentages}
          />
        </td>
        <td className="text-center">{`${deckStats.mastery}%`}</td>
        <td className="text-center">{deckStats.seenCount + '/' + deckStats.cardCount}</td>
      </tr>
    );
  }

  renderLearnerList() {
    return (
      <ul className="learner-list">
        {this.renderLearnerBlocks()}
        {this.renderNewLearnerBlock()}
        {this.renderLearnersNotShownBlock()}
      </ul>
    );
  }

  renderLearnerBlocks() {
    if (this.props.isLoadingLearners) { 
      return null;
    };

    const pack = this.props.pack;

    const learnersMap = this.state.learnersMap;
    const learnerIds = pack.transformedLearnerIds || pack.learnerIds;

    const learnerBlocks = learnerIds.map((learnerId, index) => {
      const learner = learnersMap[learnerId];

      if (!learner) {
        return null;
      }

      if (pack.permission == 'admin') {
        return this.renderLearnerBlock(learner, index);
      }

      if (learner.flags.isCurrentUser) {
        return this.renderLearnerBlock(learner, index);
      }

      if (learner.permission == 'admin') {
        return this.renderLearnerBlock(learner, index);
      }

      return null;
    });

    return learnerBlocks;
  }

  renderLearnerBlock(learner, index) {
    // we render the admin learner row for BSC admins even if they aren't Pack Admins. This enables BSC admins to always be able to make themselves admins of any Pack
    const isUserAnAdmin = this.props.pack.permission == 'admin' || this.props.currentUser.flags.isBscAdmin;

    if (this.props.isMobileViewportSize) {
      return this.renderLearnerTile(learner, index);
    }

    return this.renderLearnerRow(learner, index);
  }

  renderLearnerRow(learner, index) {
    const pack = this.props.pack;
    const selectedIds = pack.localLearnerTransforms?.selections?.ids || [];

    const isSelected = selectedIds.indexOf(learner.userId) != -1;

    return (
      <LearnerRow
        currentUser={this.props.currentUser}
        learner={learner}
        learnerIndex={index}
        isSelected={isSelected}
        key={`learner-${learner.userId}`}
        onChangePermissionRequest={this.performPermissionChange}
        onCheckboxClick={this.handleLearnerCheckboxClick}
        onRemoveLearnerRequest={this.performRemoveLearner}
        pack={pack}
      />
    );
  }

  renderLearnerTile(learner, index) {
    return (
      <LearnerTile
        currentUser={this.props.currentUser}
        learner={learner}
        learnerIndex={index}
        key={`learner-${learner.userId}`}
        onChangePermissionRequest={this.performPermissionChange}
        onCheckboxClick={this.handleLearnerCheckboxClick}
        onRemoveLearnerRequest={this.performRemoveLearner}
        pack={this.props.pack}
      />
    );
  }

  renderNewLearnerBlock() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    if (this.state.learnersFilterText != '') {
      return null;
    }

    const pulseAddLearnerButtonClass = this.state.shouldPulseAddLearnerButton
      ? 'pulse'
      : '';
    const needsLearnersClass =
      this.props.pack.learners.length < 2 ? 'needs-learners' : '';
    const addLearnerButtonClasses = toClassStr([
      'add-learner-button',
      pulseAddLearnerButtonClass,
      needsLearnersClass,
    ]);

    return (
      <li
        className="new-learner-block"
        onClick={this.handleAddNewLearnerButtonClick}
      >
        <div className="first-section">
          <div className={addLearnerButtonClasses}></div>

          <div className="label-and-bar">
            <div className="new-learner-label">Add New Learner</div>
            {/* <div className="new-learner-progress-bar" /> */}
          </div>
        </div>
      </li>
    );
  }

  renderLearnersNotShownBlock() {
    if (this.props.pack.permission == 'admin') { 
      return null;
    }

    return (
      <div className="learners-not-shown-block">
        <div className="block-heading">Looking for other Class Learners?</div>
        <div className="block-message">The full list of learners is accessible to Class admins. To gain admin status, an admin (see above) must grant you permission.</div>
      </div>
    )
  }

  renderAddNewLearnerButton() {
    if (this.props.pack.permission != 'admin') {
      return null;
    }

    if (this.state.learnersFilterText != '') {
      return null;
    }

    const pulseAddLearnerButtonClass = this.state.shouldPulseAddLearnerButton ? 'pulse' : '';
    const addLearnerButtonClasses = toClassStr(['add-new-learner-button', pulseAddLearnerButtonClass,]);

    return (
      <CircledAddButton
        addClasses={addLearnerButtonClasses}
        onClick={this.handleAddNewLearnerButtonClick}
        tooltipContent="Invite a Learner to this Class"
        tooltipPosition="left"
      />
    );
  }

  renderPaginationButton() {
    if (this.props.areLearnersFullyPopulated) {
      return null;
    }

    return (
      <SimpleTextButton
        addClasses="pagination-button"
        isProcessing={this.props.isProcessingLearnerPagination}
        label={
          'See all ' +
          NumberHelper.displayNumber(this.props.pack.subscriberCount) +
          ' Learners'
        }
        onClick={this.handlePaginationButtonClick}
      />
    );
  }

  renderLoading() {
    if ( !this.props.isLoadingLearners ) {
      return null;
    }
    return (
      <LoadingOverlay
        addClasses='full-page'
        isOpen={true}
        message={"Loading Learners"}
        shouldTransitionIn={true}
        shouldTransitionOut={true}
      />
    )
  }

  renderFooter() {
    return (
      <footer className="pack-learner-list-footer">
        {this.renderShareLink()}
        {this.renderPrivacyButtons()}
      </footer>
    );
  }

  renderShareLink() {
    if (this.props.pack.isPrivate) {
      return <div className="share-class no-share" />;
    }

    const pack = this.props.pack;
    const shareLink = pack.permission == 'admin'
      ? pack.paths.shareLink
      : pack.paths.shareCompactLink;

    return (
      <div className="share-class" title="Copy link to share this Class">
        <div className="share-link">{shareLink}</div>

        <CopyToClipboardLink
          isDisplayedInInputElem={false}
          inputElemSelector={null}
          value={shareLink}
        />
      </div>
    );
  }

  renderPrivacyButtons() {
    if (this.props.pack.permission == 'admin') {
      return (
        <RadioButtonsField
          addClasses="pack-privacy-buttons"
          buttons={PRIVACY_OPTIONS}
          isInline={true}
          name="privacyOption"
          onClick={(value) => this.handlePrivacyButtonClick(value)}
          value={this.props.pack.flags.isPrivate ? 'private' : 'public'}
        />
      );
    }

    return <div className="pack-privacy-buttons" />;
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddNewLearnerButtonClick = (e) => {
    e.stopPropagation();
    this.triggerSharePackModalSetOpen();
  };

  handleBulkActionsRetractUpButton = () => {
    this.setState({
      isBulkActionsBarOpen: false,
    });
  };

  handleBulkPermissionPulldownButtonClick = () => {
    this.setState({
      isBulkPermissionPulldownOpen: !this.state.isBulkPermissionPulldownOpen,
    });
  };

  handleBulkPermissionOutsideClick = () => {
    this.setState({
      isBulkPermissionPulldownOpen: false,
    });
  };

  handleBulkPermissionGoButtonClick = () => {
    const eligibleCount = this.getSelectedNonAdminLearners().length;

    if (eligibleCount > 0) {
      const message = `You are about to change permissions for ${eligibleCount} Learner(s). NOTE: Any admin users currently selected will not be changed by a bulk action. They must be changed individually.`;

      this.triggerCautionModalOpen({
        message: message,
        resolveButtonText: 'Ok',
        onResolution: () => {
          this.triggerCautionModalClose();
          this.performBulkPermissionsChange();
        },
      });

    } else {
      this.triggerInfoModalOpen({
        message:
          'All currently selected learners have admin permissions. Admin users can not be changed by a bulk action. They must be changed individually.',
        resolveButtonText: 'Ok',
        title: 'Notice',
      });
    }
  };

  handleBulkPermissionOptionClick = (optionId) => {
    this.setState({
      isBulkPermissionPulldownOpen: false,
      bulkPermission: optionId,
    });
  };

  handleBulkRemoveButtonClick = () => {
    const eligibleCount = this.getSelectedNonAdminLearners().length;

    if (eligibleCount > 0) {
      const message = `You are about to remove ${eligibleCount} Learner(s) from this class. This action cannot be undone. NOTE: Any admin users currently selected will not be changed by a bulk action. They must be changed individually.`;

      this.triggerCautionModalOpen({
        message: message,
        resolveButtonText: 'Ok',
        onResolution: () => {
          this.triggerCautionModalClose();
          this.performBulkRemove();
        },
      });
    } else {
      this.triggerInfoModalOpen({
        message:
          'All currently selected learners have admin permissions. Admin users can not be changed by a bulk action. They must be changed individually.',
        resolveButtonText: 'Ok',
        title: 'Notice',
      });
    }
  };

  handleClearFilterTextButtonClick = () => {
    packLearnerTransform.destroy(this.props.currentUser.userId, this.props.pack.packId, 'filters', {
      key: 'fullName',
    });
  }

  handleConfirmBulkActionsModalCloseRequest() {
    this.setState({
      isConfirmBulkActionsModalOpen: false,
    });
  }

  handleLearnerCheckboxClick = (learnerId) => {
    const isBulkActionsBarOpen = (this.props.pack.localLearnerTransforms?.selections?.ids?.length > 0);

    this.setState({
      isBulkActionsBarOpen: isBulkActionsBarOpen,
    });
  }

  handleLearnerHeadingClick(e) {
    e.stopPropagation();

    const sortColumn = 'users.first_name';
    const sortDirection =
      this.props.learnersSortColumn == 'users.first_name'
        ? this.toggleSortDirection()
        : this.props.learnersSortDirection;

    this.handleSortLearnersRequest(sortColumn, sortDirection);
  }

  handleFilterBoxChange = (e) => {
    const q = e.target.value;
    // throttle requests

    packLearnerTransform.update(this.props.currentUser.userId, this.props.pack.packId, 'filters', {
      key: 'fullName',
      // packId: this.props.pack.packId,
      q: q,
    });
  }

  handleSearchBoxChange = (e) => {
    const searchText = e.target.value;
    this.props.onLearnerSearchBoxChange(searchText);
  };

  handleSearchLearnersRequest = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    this.props.onSearchLearnersRequest();
  };

  handleSelectAllCheckboxClick = () => {
    const checkboxState = this.getSelectAllCheckboxState();
    let isSelected = (checkboxState != 'all');

    packLearnerTransform.update(this.props.currentUser.userId, this.props.pack.packId, 'selections', {
      learnerIds: this.props.pack.learnerIds,
      isSelected: isSelected,
    }).then(() => {
      const isBulkActionsBarOpen = (this.props.pack.localLearnerTransforms?.selections?.ids?.length > 0);

      this.setState({
        isBulkActionsBarOpen: isBulkActionsBarOpen,
      });
    });
  }

  handleClearSearchTextButtonClick = () => {
    this.props.onLearnerSearchBoxChange('');
  };

  handleLastNameHeadingClick(e) {
    e.stopPropagation();

    const sortColumn = 'lastName';
    const sortDirection =
      this.props.learnersSortColumn == 'lastName'
        ? this.toggleSortDirection()
        : this.props.learnersSortDirection;

    this.handleSortLearnersRequest(sortColumn, sortDirection);
  }

  handlePackPrivacyChanged = () => {
    this.triggerRefreshPackDetailSection();
    this.triggerEditPackPrivacyModalClose();
  };

  handlePaginationButtonClick = () => {
    this.props.onPaginateLearnersRequest();
  };

  handlePrivacyButtonClick(value) {
    const newPrivacyState = value == 'private';

    if (newPrivacyState == this.props.pack.flags.isPrivate) {
      return false;
    }

    if (!this.props.currentUser.flags.isPro) {
      Tracker.trackPaywallWarning('privacy');
      this.triggerPrivacyUpgradeModalOpen()
    } else {
      this.setState(
        {
          isProcessingPrivacyRequest: true,
        },
        () => {
          this.triggerEditPackPrivacyModalOpen(newPrivacyState);
        },
      );
    }
  }

  handlePulseAddLearnerButtonRequest = () => {
    this.setState({
      shouldPulseAddLearnerButton: true,
    });
  };

  handleShowBulkActionsBarRequest() {
    this.setState({
      isBulkActionsBarOpen: true,
    });
  }

  handleFilterLearnersRequest(filterText) {
    if (this.props.areLearnersFullyPopulated) {

      this.setState({
          learnersFilterText: filterText,
        }, () => {
          this.localFilterLearners();
        },
      );
    } else {
      // retrieve full set of learners. subsequent filter characters will be performed locally (eventuallY)
      this.setState(
        {
          currentPackLearners: [],
          learnersFilterText: filterText,
        },
        () => {
          this.getAllCurrentPackLearnerPages();
        },
      );
    }
  }

  handleLearnerSearchBoxChange = (searchText) => {
    let hasEmptyLearnerSearchResults = this.state.hasEmptyLearnerSearchResults;

    if (searchText == '') {
      hasEmptyLearnerSearchResults = false;
    }

    this.setState({
      hasEmptyLearnerSearchResults: hasEmptyLearnerSearchResults,
      learnersFilterText: searchText,
    });
  };

  handleSortLearnersRequest(column, direction) {

    // Reset Direction when Column Choice Changes
    direction = this.state.learnersSortColumn == column ? direction : 'desc';

    if (this.props.hasEmptyLearnerSearchResults) {
      return false;
    }

    if (this.props.areLearnersFullyPopulated) {
      // perform sort locally over full local set of learners
      this.setState(
        {
          learnersSortColumn: column,
          learnersSortDirection: direction,
        },
        () => {
          this.localSortLearners();
        },
      );
    } else {
      // perform sort on server over remote set of learners using sort params
      this.setState(
        {
          learnersSortColumn: column,
          learnersSortDirection: direction,
        },
        () => {
          this.getAllCurrentPackLearnerPages();
        },
      );
    }
  }

  handleSearchLearnersRequest = () => {
    // retrieve full set of learners from server, filtered by this.state.learnerSearchText and sorted by this.state.learnersSortColumn and learnersSortdirection
    this.setState(
      {
        currentPackLearners: [],
      },
      () => {
        this.getAllCurrentPackLearnerPages();
      },
    );
  };

  handleUserCardUpdated = (data) => {
    const updatedCardData = data.userCard;
    const updatedCardId = updatedCardData.cardId;
    let currentCard = { ...this.state.currentCard };
    let currentCards = [...this.state.currentCards];
    let areChanges = false;

    let updatedCardIndex = currentCards.findIndex((card) => {
      return card.cardId == updatedCardId;
    });

    if (updatedCardIndex != -1) {
      currentCards[updatedCardIndex].level = updatedCardData.level;
      areChanges = true;
    }

    if (currentCard.cardId == updatedCardId) {
      currentCard.level = updatedCardData.level;
      areChanges = true;
    }

    if (areChanges) {
      this.setState({
        currentCard: currentCard,
        currentCards: currentCards,
      });
    }
  };

  handleUserFlagUpdated = (data) => {
    const currentUser = {...this.state.currentUser};
    const userFlags = {...data.flags, ...currentUser.flags};

    currentUser.flags = userFlags;

    this.setState({
      currentUser: currentUser,
    });
  }

  localFilterLearners(callback) {
    const filterText = this.state.learnersFilterText.toLowerCase();
    let currentLearners = [...this.props.pack.learners];
    let filteredLearners = currentLearners;

    if (filterText.length > 0) {
      filteredLearners = currentLearners.filter((learner) => {
        return (
          learner.firstName.toLowerCase().indexOf(filterText) != -1 ||
          learner.lastName.toLowerCase().indexOf(filterText) != -1
        );
      });
    } else {
      filteredLearners = currentLearners;
    }

    this.setState(
      {
        locallyFilteredLearners: filteredLearners,
      },
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }

  getAllCurrentPackLearnerPages = () => {

  }

  localSortLearners(callback) {
    const sortDirection = this.state.learnersSortDirection || 'asc';
    let sortParam = this.state.learnersSortColumn || 'lastName';
    if ( sortParam.match(/\./) ) {
      // Converting AR => Local Sort Name
      const augmentedSortName = sortParam.replaceAll(/.+\./ig, '').replaceAll('_', ' ')
      sortParam = StringHelper.toCamelCase(augmentedSortName);
    }

    let currentLearners = this.state.learnersFilterText
      ? [...this.state.locallyFilteredLearners]
      : [...this.props.pack.learners];

    const cmp = (a, b) => (a > b) - (a < b);

    const sortedLearners = currentLearners.sort((a,b) => {
      if (sortDirection == 'asc') {
        return cmp(a["permission"], b["permission"]) || cmp(a[sortParam], b[sortParam])
      } else {
        return cmp(a["permission"], b["permission"]) || cmp(b[sortParam], a[sortParam])
      }
    });

    this.setState(
      {
        locallyFilteredLearners: sortedLearners,
      },
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerCautionModalOpen(viewProps) {
    EventManager.emitEvent('caution-modal:open', viewProps);
  }

  triggerCautionModalClose(viewProps) {
    EventManager.emitEvent('caution-modal:close', viewProps);
  }

  triggerInfoModalOpen(viewProps) {
    EventManager.emitEvent('info-modal:open', viewProps);
  }

  triggerInfoModalClose(viewProps) {
    EventManager.emitEvent('info-modal:close', viewProps);
  }

  triggerEditPackPrivacyModalOpen(newPrivacyState) {
    EventManager.emitEvent('edit-pack-privacy-modal:open', {
      authenticityToken: this.props.authenticityToken,
      newPrivacyState: newPrivacyState,
      pack: this.props.pack,
      onPackPrivacyChanged: this.handlePackPrivacyChanged,
    });
  }

  triggerEditPackPrivacyModalClose() {
    EventManager.emitEvent('edit-pack-privacy-modal:close', {});
  }

  triggerInstructorUpgradeModalOpen(opts) {
    EventManager.emitEvent('upgrade-modal:open', {
      paywall: opts.paywall,
      purpose: opts.purpose,
      featuresList: opts.featuresList,
    });
  }

  triggerLearnerFiltersChangeRequest = (filterData) => {
    EventManager.emitEvent('learner-filters:change-request', filterData);
  }

  triggerPrivacyUpgradeModalOpen() {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: 'Make Class Private',
      paywall: 'privacy',
      featuresList: 'list-3',
    });
  }

  triggerRefreshPackDetailSection() {
    EventManager.emitEvent('refresh-pack-detail-section', {});
  }

  triggerSharePackModalSetOpen() {
    EventManager.emitEvent('share-pack-modal-set:open', {
      authenticityToken: this.props.authenticityToken,
      pack: this.props.pack,
    });
  }

  triggerUpgradeModalOpen(desiredAction, paywall, featuresList) {
    EventManager.emitEvent('upgrade-modal:open', {
      desiredAction: desiredAction,
      paywall: paywall,
      featuresList: featuresList,
    });
  }

  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals() {
    clearTimeout(this.statsRetryTimeout);
  }

  getSelectAllCheckboxState = () => {
    const selectedCount = this.props.pack.localLearnerTransforms?.selections?.ids?.length; 
    const totalCount = this.props.pack.learnerIds.length; 
    const checkboxState = (selectedCount == 0) ? 'none' : (selectedCount == totalCount) ? 'all' : 'mixed';

    return checkboxState;
  }

  getSelectedLearnerIds() {
    // this function returns state of selected learners from localStorage
    const userId = this.props.currentUser.userId;
    const packId = this.props.pack.packId;

    return userLocalStore.getSelectedPackLearnerIds(userId, packId);
  }

  getSelectedNonAdminLearners = () => {
    const selectedLearnerIds = this.getSelectedLearnerIds();

    if (selectedLearnerIds?.length < 1) {
      return null;
    }

    return this.props.learners.filter(learner => {
      return (learner.permission != 'admin' && selectedLearnerIds.indexOf(learner.userId) != -1);
    });
  };

  getPackSelectedState() {
    let learnerCount = this.props.pack.learners.length;
    let selectCount = this.getSelectedLearnerIds().length;

    if (selectCount == 0) {
      return 'none';
    }

    if (selectCount == learnerCount) {
      return 'all';
    }

    return 'mixed';
  }

  // TODO: Refactor getPurpose, hasResource, and getResource under a new PackMetadata model
  getPurpose = () => {
    let purposeLabel = null;

    if (this.props.metadata && this.props.metadata.purposes) {
      const purposes = this.props.metadata.purposes;
      const purposeKeys = Object.keys(purposes);

      for (let i = 0; i <= purposeKeys.length; i++) {
        const purposeKey = purposeKeys[i];
        const purpose = purposes[purposeKey];

        if (purpose && purpose.value == true) {
          purposeLabel = purpose.field_label;
          break;
        }
      }
    }

    return purposeLabel;
  };

  hasResource = (resourceKey, testProps) => {
    const props = testProps || this.props;

    return !!(
      props.metadata &&
      props.metadata.global &&
      props.metadata.global[resourceKey] &&
      props.metadata.global[resourceKey].value
    );
  };

  getInitBulkActionsBarOpen = () => {
    return (this.props.pack.localLearnerTransforms?.selections?.ids?.length > 0)
  }

  getResource = (resourceKey) => {
    if (this.hasResource(resourceKey)) {
      return this.props.metadata.global[resourceKey].value;
    }

    return null;
  };

  performBulkAction = (bulkActionFunction) => {
    const pack = this.props.pack;

    this.setState({ 
      isConfirmBulkActionsModalOpen: false,
      isProcessingBulkAction: true,
    });

    const learnersMap = this.state.learnersMap;

    // Note: We need the intersection of selected & filtered users.
    const filteredIds = pack.transformedLearnerIds || pack.learnerIds;
    const selectedIds = pack.localLearnerTransforms.selections.ids;

    const selectedFilteredLearnerIds = filteredIds.filter(filteredId => {
      return (selectedIds.indexOf(filteredId) != -1);
    });

    selectedFilteredLearnerIds.forEach((learnerId) => {
      const learner = learnersMap[learnerId];

      if (learner) {
        const permissionOptionId = this.state.bulkPermission || null;

        // do not perform bulk actions on admin users
        if (learner.permission != 'admin') {
          bulkActionFunction(learner, permissionOptionId);
        }
      }
    });

    this.setState({
      isProcessingBulkAction: false,
    });
  };

  performBulkPermissionsChange = (learner, optionId=null) => {
    this.performBulkAction(this.performPermissionChange);
  };

  performBulkRemove = (learner, optionId=null) => {
    this.performBulkAction(this.performRemoveLearner);
  };

  performPermissionChange = (learner, optionId) => {
    const learnerData = {
      permissionOptionId: optionId,
      discoveryMedium: learner.discoveryMedium,
    };

    packLearner.update(this.props.pack.packId, learner.userId, learnerData).then(() => {
      this.setState({
        isProcessingPermissionChange: false,
      });
    });
  };

  performRemoveLearner = (learner, optionId=null) => {
    packLearner.remove(this.props.pack.packId, learner.userId);
  };

  updateLearnersMap = () => {
    const pack = this.props.pack;
    const learners = pack.learners;

    if (!learners) {
      return false;
    }

    const learnersMap = {};

    learners.forEach(learner => {
      learnersMap[learner.userId] = learner;
    });

    this.setState({
      learnersMap: learnersMap,
    });
  }
}

export default PackLearnersSection;
