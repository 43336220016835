
// substrate and utils
import EventManager              from '@brainscape/event-manager';
import PropTypes                 from 'prop-types';
import React                     from 'react';
import scrollIntoView            from 'scroll-into-view-if-needed';
import UiHelper                  from '_utils/UiHelper';
import {toClassStr}              from '_utils/UiHelper';

// models
import cardConfidence            from '_models/cardConfidence';

// sub-components
import SmartCard                 from '_views/shared/smart-cards/SmartCard';

import {
  CircledAddButton,
}                                from '_views/shared/IconButton';



const PT = {
  addClasses:                   PropTypes.string,
  cardLimitCount:               PropTypes.number,
  cardLimitPercentage:          PropTypes.number,
  cards:                        PropTypes.array,
  cardMode:                     PropTypes.string, // 'edit' or 'display'
  context:                      PropTypes.string,
  currentCardId:                PropTypes.node,
  currentUser:                  PropTypes.object,
  deck:                         PropTypes.object,
  editMode:                     PropTypes.string,
  editorHasChanges:             PropTypes.bool,
  format:                       PropTypes.string,
  hasCardLimit:                 PropTypes.bool,
  isAppendable:                 PropTypes.bool,
  isBlurred:                    PropTypes.bool,
  isMobileViewportSize:         PropTypes.bool,
  isPreviewModal:               PropTypes.bool,
  isShowingAdditionalFields:    PropTypes.bool,
  isViewingSource:              PropTypes.bool,
  newCardId:                    PropTypes.node,
  onViewLargeImageRequest:      PropTypes.func,
  onKeyDown:                    PropTypes.func,
  pack:                         PropTypes.object,
  shouldSuppressCardSelection:  PropTypes.bool,
  viewportStyle:                PropTypes.string,  // 'desktop', 'mobile'
};


class SmartCardList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editingCardId:                null,
      isListeningToOutsideClicks:   false,
      isProcessing:                 false,
      isProcessingConfidenceChange: false,
      pulldownsLedger: {},
    }

    this.events = new EventManager();

    this._isMounted = false;

    this.setCurrentCardTimeout = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
    this.subscribeToEvents();
    this.selectCurrentCard();
    this.setupPulldownsLedger();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentCardId && this.props.currentCardId && (prevProps.currentCardId != this.props.currentCardId)) {
      this.selectCurrentCard();

      return true;
    }

    if (prevProps.editMode != this.props.editMode) {
      this.selectCurrentCard();
    }
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this.unsubscribeToEvents();
    this._isMounted = false;
  }


  /*
  ==================================================
   EVENT SUBSCRIPTIONS
  ==================================================
  */

  subscribeToEvents = () => {
    this.events.addListener('current-card:scroll-to-request', this.handleCurrentCardScrollToRequest);
  } 

  unsubscribeToEvents = () => {
    if (this._isMounted) {
      this.events.disable();
    }
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */


  render() {    
    const contextClass = `${this.props.context}-context`;
    const editModeClass = (this.props.context == 'editor') ? `${this.props.editMode}-edit-mode` : '';
    const layoutClass = `${this.props.layout}-layout`;
    const formatClass = `${this.props.format}-format`;

    const inputClass = `${this.getInputType()}-input`;
    const classes = toClassStr(['smart-card-list', contextClass, editModeClass, formatClass, inputClass, this.props.addClasses]);

    const smartCards = this.props.cards.map((rowCard, index) => {
      return this.renderSmartCardRow(rowCard, index);
    });

    return (
      <div className={classes}>
        {smartCards}
        {this.renderListFooter()}
      </div>
    );
  }

  renderSmartCardRow(rowCard, index) {
    const currentCardId = this.props.currentCardId;
    const newCardId = this.props.newCardId;

    const isCurrentCard = (currentCardId) ? (rowCard.cardId == currentCardId) : false;
    const isNewCard = (newCardId) ? (rowCard.cardId == newCardId) : false;

    const cardRowId = `smart-card-row-${rowCard.cardId}`;

    const isCurrentCardClass = (isCurrentCard) ? 'is-current-card' : '';
    const isNewCardClass = (isNewCard) ? 'is-new-card' : '';
    const isBlurred = this.props.hasCardLimit ? this.isCardBlurred(rowCard, index + 1) : false;

    const cardMode = (rowCard.cardId == this.state.editingCardId) ? 'edit' : this.props.cardMode;
    const editMode = (rowCard.cardId == this.state.editingCardId) ? 'advanced' : this.props.editMode;

    const classes = toClassStr(['smart-card-row', isCurrentCardClass, isNewCardClass]);

    return (
      <div className={classes} id={cardRowId} key={`smart-card-row-${rowCard.cardId}`}>
        <SmartCard
          card={rowCard}
          cardMode={cardMode}
          context={this.props.context}
          currentUser={this.props.currentUser}
          deck={this.props.deck}
          editMode={this.props.editMode}
          editorHasChanges={this.props.editorHasChanges}
          format="md"
          isBlurred={isBlurred}
          isCurrentCard={isCurrentCard}
          isMobileViewportSize={this.props.isMobileViewportSize}
          isMonitoringOutsideClicks={false}
          isNewCard={isNewCard}
          isPreviewModal={this.props.isPreviewModal}
          isShowingAdditionalFields={this.props.isShowingAdditionalFields}
          isViewingSource={this.props.isViewingSource}
          layout="list"
          onKeyDown={this.props.onKeyDown}
          packId={this.props.pack.packId}
          rowNumber={index + 1}
        />
      </div>
    );
  }

  renderListFooter() {
    if (this.props.context != 'editor') {
      return null;
    }

    return (
      <div className="smart-card-row new-card-row">

        <div className="card-header" />

        <div className="tip-and-button">
        
          <div className="smart-card new-card-button edit-card-mode list-layout editor-context" onClick={this.handleAddCardButtonClick}>

            <CircledAddButton 
              shouldAllowClickPropagation={true}
            />

            <div className="add-prompt create-prompt">Create New Card (or TAB through to next card)</div>
          </div>
        </div>

        <div className="card-footer" />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleAddCardButtonClick = () => {
    this.triggerAddNewCardRequest();
  }

  handleCurrentCardScrollToRequest = () => {
    this.selectCurrentCard();
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerAddNewCardRequest = () => {
    EventManager.emitEvent('card:add-new-card-request', {
      editMode: this.props.editMode,
    });
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.setCurrentCardTimeout);
    clearTimeout(this.initialScrollTimeout);
  }

  getInputType = () => {
    if (this.props.isShowingAdditionalFields) {
      return 'fields';
    }

    if (this.props.isShowingAdditionalFields) {
      return 'fields';
    }

    if (this.props.isViewingSource) {
      return 'source';
    }

    return 'text';
  }

  isCardBlurred = (card, rowNumber) => {
    if (!this.props.hasCardLimit) {
      return false;
    }

    const cardLimit = Math.max(this.props.cardLimitCount, ((this.props.cardLimitPercentage/100) * this.props.cards.length), 0);

    return (rowNumber > cardLimit);
  }

  oneThroughFive = (level) => {
    const i = isNaN(parseInt(level, 10)) ? 1 : level;
    return Math.min(5, Math.max(1, i));
  }

  scrollToSmartCardRowById(id) {
    const rowSelector = `#${id}`;
    const currentSmartCardRow = document.querySelector(rowSelector);

    if (currentSmartCardRow) {
      scrollIntoView(currentSmartCardRow, {
        behavior: 'smooth',
        block: 'start',
        scrollMode: 'always',
      });
    }
  }

  selectCurrentCard = () => {
    if (!this.props.currentCardId) {
      return null;
    }

    const smartCardRowId = `smart-card-row-${this.props.currentCardId}`

    clearTimeout(this.setCurrentCardTimeout);

    this.setCurrentCardTimeout = setTimeout(() => {
      this.scrollToSmartCardRowById(smartCardRowId);
    }, 0);
  }

  setupPulldownsLedger = () => {
    if (this.props.context != 'preview') {
      return false;
    }

    const pulldownsLedger = {};

    this.props.cards.forEach(card => {
      pulldownsLedger[card.cardId] = false;
    });

    this.setState({
      pulldownsLedger: pulldownsLedger,
    });
  }
}

SmartCardList.propTypes = PT;

export default SmartCardList;
