
import {modalCount}             from '_views/shared/Modal';
import ConfidenceLevelButtons   from '_study/ConfidenceLevelButtons';
import EventManager             from '@brainscape/event-manager';
import React                    from 'react';

import {toClassStr} from '_utils/UiHelper';

class StudyCardBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAnswerBarShowing: false,
      isPulsing: false,
      isQuestionBarShowing: false,
    };

    /*
      this.props:
        addClasses,
        contentType,
        isAnswerShowing,
        level,
        onAnswerCardbarClick,
        onConfidenceRating,
        onCardFlipRequest,
        onQuestionCardbarClick,
        highlightedRatingButton,
        shouldCaptureKeyboardInput,
        shouldPulseAnswerCardbar,
        shouldPulseQuestionCardbar,
    */

    this._isMounted = false;
    this.entryExitTimeout = null;
    this.pulsationTimeout = null;

    this.ENTRY_EXIT_DELAY = 100; // allows for mounting of component before commencement of entry/exit transition
    this.ENTRY_EXIT_DURATION = 2000;
    this.PULSATIOM_DELAY = 250; // allows for mounting of component and entry/exit animation before commencement of pulsation
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.activateKeyboardMonitor();
    this.clearTimeouts();
    this.performEntryExitAnimations();
  }

  componentDidUpdate(prevProps) {
    clearTimeout(this.pulsationTimeout);

    if (this.props.isAnswerShowing != prevProps.isAnswerShowing) {
      this.performEntryExitAnimations();
    }

    if (this.props.shouldPulseQuestionCardbar || this.props.shouldPulseAnswerCardbar) {
      this.invokePulsation();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.clearTimeouts();
    this.deactivateKeyboardMonitor();
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const smartCardBarsClass = (this.props.contentType == 'md') ? 'smart-card-bars' : '';
    const classes = toClassStr(['study-card-bars', smartCardBarsClass, this.props.addClasses]);

    return (
      <div className={classes}>
        {this.renderQuestionBar()}
        {this.renderAnswerBar()}
      </div>
    );
  }

  renderQuestionBar() {
    const confidenceClass = 'confidence-' + this.props.confidenceLevel;
    const entryClass = this.state.isQuestionBarShowing ? 'is-showing' : '';
    const isDealingClass = this.props.isDealingNewCard ? 'is-dealing' : '';
    const pulseClass = this.props.shouldPulseQuestionCardbar && this.state.isPulsing ? 'pulse' : '';

    const classes = toClassStr(['study-card-bar question-bar', confidenceClass, entryClass, isDealingClass, pulseClass, this.props.addClasses]);

    return (
      <div className={classes} onClick={(e) => this.handleQuestionBarClick(e)} title="Press space on your keyboard">
        <div className="bar-text">Reveal Answer</div>
      </div>
    );
  }

  renderAnswerBar() {
    const confidenceClass = 'confidence-' + this.props.confidenceLevel;
    const entryClass = this.state.isAnswerBarShowing ? 'is-showing' : '';
    const isDealingClass = this.props.isDealingNewCard ? 'is-dealing' : '';
    const pulseClass = this.props.shouldPulseAnswerCardbar && this.state.isPulsing ? 'pulse' : '';

    const classes = toClassStr(['study-card-bar answer-bar', confidenceClass, entryClass, isDealingClass, pulseClass, this.props.addClasses]);

    return (
      <div className={classes}>
        <div className="card-bar-prompt">How well did you know this?</div>
        <ConfidenceLevelButtons
          highlightedRatingButton={this.props.highlightedRatingButton}
          onButtonClick={(rating) => this.handleAnswerBarButtonClick(rating)}
        />
      </div>
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleKeydown(e) {
    if (!this.props.shouldCaptureKeyboardInput || modalCount() > 0) {
      return null;
    }

    e.stopPropagation();
    const keyCode = e.keyCode;

    switch (keyCode) {
      case 32: // space bar
        e.preventDefault();
        this.handleCardFlipRequest();
        break;
      case 97: // number pad 1
      case 49: // number 1
        e.preventDefault();
        this.handleKeyboardRatingInput(1);
        break;
      case 98: // number pad 2
      case 50: // number 2
        e.preventDefault();
        this.handleKeyboardRatingInput(2);
        break;
      case 99: // number pad     3
      case 51: // number 3
        e.preventDefault();
        this.handleKeyboardRatingInput(3);
        break;
      case 100: // number pad 4
      case 52: // number 4
        e.preventDefault();
        this.handleKeyboardRatingInput(4);
        break;
      case 101: // number pad 5
      case 53: // number 5
        e.preventDefault();
        this.handleKeyboardRatingInput(5);
        break;
    }
  }

  handleAnswerBarButtonClick(rating) {
    this.triggerStopAllSoundsRequest();

    // handle any FTSE messaging, then process rating
    if (this.props.shouldPulseAnswerCardbar) {
      this.setState({
        isPulsing: false
      }, () => {
        // ftse: dismiss answer bubble, display cbr bubble, and set post cbr bubble action upstream
        const ftsePostCbrBubbleAction = () => this.props.onConfidenceRating(rating);
        this.props.onAnswerCardbarClick(rating, ftsePostCbrBubbleAction);
        ;
      });
    } else {
      this.props.onConfidenceRating(rating);
    }
  }

  handleKeyboardRatingInput(rating) {
    this.handleAnswerBarButtonClick(rating);
  }

  handleQuestionBarClick(e) {
    // handle any FTSE messaging, then process request
    if (e) {
      e.stopPropagation();
    }

    this.triggerStopAllSoundsRequest();

    if (this.props.shouldPulseQuestionCardbar) {
      this.setState({
        isPulsing: false
      }, () => {
        this.props.onQuestionCardbarClick(); // dismisses ftse question bubble upstream
        this.props.onCardFlipRequest();
      });
    } else {
      this.props.onCardFlipRequest();
    }
  }

  handleCardFlipRequest() {
    this.triggerStopAllSoundsRequest();

    if (!this.props.isAnswerShowing) {
      this.handleQuestionBarClick();
    } else {
      this.props.onCardFlipRequest();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerStopAllSoundsRequest() {
    EventManager.emitEvent('sound-player:stop-all-request', {});
  }


  /*
  ==================================================
   ANIMATIONS
  ==================================================
  */

  performEntryExitAnimations() {
    clearTimeout(this.entryExitTimeout);

    this.entryExitTimeout = setTimeout(() => {
      if (this.props.isAnswerShowing) {
        this.invokeAnswerBarEntrance();
      } else {
        this.invokeQuestionBarEntrance();
      }
    }, this.ENTRY_EXIT_DELAY);
  }

  invokeQuestionBarEntrance() {
    this.setState({
      isAnswerBarShowing: false,
      isQuestionBarShowing: true,
    });
  }

  invokeAnswerBarEntrance() {
    this.setState({
      isAnswerBarShowing: true,
      isQuestionBarShowing: false,
    });
  }

  invokePulsation() {
    clearTimeout(this.pulsationTimeout);

    this.pulsationTimeout = setTimeout(() => {
      this.setState({
        isPulsing: true
      });
      clearTimeout(this.pulsationTimeout);
    }, this.PULSATIOM_DELAY);
  }


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  activateKeyboardMonitor() {
    document.addEventListener('keydown', (e) => this.handleKeydown(e));
  }

  deactivateKeyboardMonitor() {
    document.removeEventListener('keydown', (e) => this.handleKeydown(e));
  }

  clearTimeouts() {
    clearTimeout(this.pulsationTimeout);
    clearTimeout(this.entryExitTimeout);
  }
}

export default StudyCardBar;
